import React from "react";

interface ButtonProps {
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  primary?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({ label, onClick, primary = true }: ButtonProps) => {
  return (
    <button
      type="button"
      className={
        "items-center font-sans rounded border px-2 py-1 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-[#ff8d8e] focus:ring-offset-2  " +
        (primary
          ? "bg-lamini-primary text-white hover:bg-[#ff8d8e] border-transparent "
          : "bg-white text-gray-700 hover:bg-gray-50 border-gray-300")
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};
