import * as React from "react";
import { ClassifierProjectList } from "./components/ClassifierProjectList";
import { useParams } from "react-router-dom";
import { ProjectDetailPage } from "./components/ProjectDetailPage";
import { Centered } from "../components/system/layouts/Centered";

function Classifier() {
  const { classifierProjectId } = useParams<{ classifierProjectId: string }>();

  if (classifierProjectId) {
    return <ProjectDetailPage />;
  }
  return (
    <Centered>
      <ClassifierProjectList />
    </Centered>
  );
}

export default Classifier;
