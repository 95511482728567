import React, { forwardRef } from "react";
import { ReactComponent as Send } from "../../../assets/send-icon.svg";

interface SendMessageProps {
  message: string;
  setMessage: (message: string) => void;
  onSendMessage: () => void;
  userTurn: boolean;
  rows: number;
}

export const SendMessage = forwardRef<HTMLTextAreaElement, SendMessageProps>(
  (props, ref) => {
    const handleSubmit = (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (props.message !== "") {
        props.onSendMessage();
        props.setMessage("");
      }
    };

    return (
      <div className="flex w-full rounded-[12px] border-[1px] border-[#9E9E9E]">
        <textarea
          value={props.message}
          ref={ref}
          rows={props.rows}
          placeholder="Type your message here…"
          className="w-full rounded-l-[12px] bg-[#1d1d1d] border-none text-white"
          onChange={(event) => props.setMessage(event.target.value)}
          onKeyDown={(event) => {
            if (props.userTurn && event.key == "Enter" && !event.shiftKey) {
              // only send message if enter is pressed without shift
              handleSubmit(event);
            }
          }}
        />
        <button
          className="px-4 rounded-r-[12px] bg-[#1d1d1d] border-none ml-[1px]"
          onClick={(event) => {
            if (props.userTurn) {
              // only send message if enter is pressed without shift
              handleSubmit(event);
            }
          }}
        >
          <Send
            fill={
              props.userTurn && props.message !== ""
                ? "lamini-primary"
                : "#6b7280"
            }
            className="w-5 h-5"
          />
        </button>
      </div>
    );
  }
);
