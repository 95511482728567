import * as React from "react";
import { TrainJob } from "../../types";
import fetchJobEval, { JobEvalResult } from "../../utils/fetchJobEval";
import TrainPageEvalView from "./TrainPageEvalView";
import { Spinner } from "../system/atoms/Spinner";
import { useParams } from "react-router-dom";
import { Centered } from "../system/layouts/Centered";
import fetchJob from "../../utils/fetchJob";
import { UserInfo } from "../../types";

interface ShareTrainJobPageProps {
  token: string;
  userInfo: UserInfo;
}

export default function ShareTrainJobPage({
  token,
  userInfo,
}: ShareTrainJobPageProps) {
  let params = useParams();
  const [job, setJob] = React.useState<TrainJob | null>(null);
  const [jobEval, setJobEval] = React.useState<Array<JobEvalResult> | null>(
    null,
  );
  const [jobEvalLoading, setJobEvalLoading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  React.useEffect(() => {
    if (params.trainJobID === undefined) {
      return;
    }
    fetchJob(params.trainJobID, setJob, () => {}, token);
    fetchJobEval(params.trainJobID, setJobEval, token, setJobEvalLoading);
  }, []);

  // fetchJob has not finished yet so don't render jobs (show loading)
  if (job === null) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Spinner enabled={true}></Spinner>
        </div>
      </div>
    );
  }

  return (
    <Centered>
      <div className="flex flex-col h-full">
        <div className="my-12">
          <h1 className="text-4xl my-4">Training Job</h1>
        </div>
        <div className="grow flex-1">
          <TrainPageEvalView
            activeJob={job}
            jobs={[]}
            setJobs={() => {}}
            jobEval={jobEval}
            token={token}
            jobEvalLoading={jobEvalLoading}
            setActiveJob={() => {}}
            shouldFetchModel={true}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            showShareIcons={false}
            userInfo={userInfo}
          />
        </div>
      </div>
    </Centered>
  );
}
