import React from "react";
import { useState } from "react";
import { Button } from "../components/system/catalyst/button";

export const useTheme = () => {
  const [isDark, setIsDark] = useState(true);

  React.useEffect(() => {
    document.documentElement.classList.toggle("dark", isDark);
    document.documentElement.classList.toggle(
      "bg-lamini-dark-background",
      isDark
    );
    document.documentElement.classList.toggle(
      "bg-lamini-light-background",
      !isDark
    );
  }, [isDark]);

  return { isDark, setIsDark };
};

export const ThemeButton = () => {
  const { isDark, setIsDark } = useTheme();

  return (
    <Button onClick={() => setIsDark(!isDark)} plain>
      {isDark ? "☀️" : "🌙"}
    </Button>
  );
};

export const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={
        "bg-lamini-light-background text-zinc-950 dark:bg-lamini-dark-background dark:text-[#ececec] transition-colors duration-300"
      }
    >
      {children}
    </div>
  );
};
