import * as React from "react";

import { statusChip } from "./statusChip";
import cancelJob from "../../utils/cancelJob";
import resumeTraining from "../../utils/resumeTraining";
import { TrainJob } from "../../types";
import { JobEvalResult } from "../../utils/fetchJobEval";
import { Spinner } from "../system/atoms/Spinner";
import InteractivePlayground, {
  get_start_message,
} from "../playground/InteractivePlayground";
import ShareModel from "./ShareModel";
import fetchModel from "../../utils/fetchModel";
import editIcon from "../../assets/edit-icon.svg";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import setCustomName from "../../utils/setCustomName";
import TrainingLog from "./TrainingLog";
import useInterval from "../../utils/useInterval";
import { Fragment } from "react";
import fetchJob from "../../utils/fetchJob";
import { ChatHistoryItem } from "../system/atoms/ChatHistory";
import TrainJobInformation from "./TrainJobInformation";
import EvalResults from "./EvalResults";
import TrainArguments from "./TrainArguments";
import { UserInfo } from "../../types";

import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
} from "recharts";
import { Button } from "../system/catalyst/button";

interface TrainPageEvalViewProps {
  activeJob: TrainJob | null;
  setActiveJob: React.Dispatch<React.SetStateAction<TrainJob | null>>;
  jobs: TrainJob[];
  setJobs: React.Dispatch<React.SetStateAction<TrainJob[]>>;
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  jobEval: Array<JobEvalResult> | null;
  token: string;
  jobEvalLoading: boolean;
  shouldFetchModel: boolean;
  showShareIcons: boolean;
  userInfo: UserInfo;
}

const EvalTab = ({ label }: { label: string }) => (
  <Tab as={Fragment}>
    {({ selected }) => (
      <button
        className={`${
          selected
            ? "text-lamini-primary border-lamini-primary font-semibold text-sm"
            : "text-zinc-600 border-zinc-600 text-sm"
        } border-0 px-8 py-1 border-b-2 hover:opacity-70 focus:outline-none`}
      >
        {label}
      </button>
    )}
  </Tab>
);

export default function TrainPageEvalView({
  activeJob,
  setActiveJob,
  jobs,
  setJobs,
  selectedTab,
  setSelectedTab,
  jobEval,
  token,
  jobEvalLoading,
  shouldFetchModel,
  showShareIcons,
  userInfo,
}: TrainPageEvalViewProps) {
  if (activeJob == null) {
    return null;
  }

  const [isHoveringName, setIsHoveringName] = React.useState<boolean>(false);
  const [isPub, setIsPub] = React.useState<boolean | null>(null);
  const [editName, setEditName] = React.useState<boolean>(false);
  const [customModelName, setCustomModelName] = React.useState<string>("");
  const [jobHistory, setJobHistory] = React.useState<any>({});
  const [chatHistory, setChatHistory] = React.useState<Array<ChatHistoryItem>>(
    get_start_message(customModelName)
  );
  const tabs = ["Eval Results", "Playground", "Loss", "Logs", "Arguments"];
  React.useEffect(() => {
    activeJob.model_name && shouldFetchModel
      ? fetchModel(activeJob.model_name, setIsPub, setCustomModelName, token)
      : setCustomModelName(""),
      [activeJob, shouldFetchModel];
  });
  React.useEffect(() => {
    setIsHoveringName(false);
    setIsPub(null);
    setEditName(false);
    setCustomModelName("");
    setJobHistory({});
    setChatHistory(get_start_message(""));
  }, [activeJob, jobs]);
  React.useEffect(() => {
    fetchJob(activeJob.job_id, () => {}, setJobHistory, token);
  }, [activeJob]);

  useInterval(() => {
    if (activeJob.model_name !== undefined && shouldFetchModel) {
      fetchModel(activeJob.model_name, setIsPub, setCustomModelName, token);
    }
  }, 60000);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const input = target[0] as HTMLInputElement;
    setCustomName(
      activeJob.model_name,
      input.value,
      token,
      activeJob,
      setActiveJob
    );

    setCustomModelName(input.value);
    setEditName(false);
  };
  let timeString = "";
  const jobHistoryValues: any[] = Object.values(jobHistory);
  if (jobHistoryValues.length > 0) {
    const minutesRemaining = Math.floor(
      jobHistoryValues[jobHistoryValues.length - 1].remaining_time / 60
    );
    timeString =
      minutesRemaining < 1
        ? "Less than 1 minute remaining"
        : minutesRemaining === 1
        ? "1 minute remaining"
        : minutesRemaining < 60
        ? `${minutesRemaining} minutes remaining`
        : `${Math.floor(minutesRemaining / 60)} hours ${
            minutesRemaining % 60
          } minutes remaining`;
  }
  const data = React.useMemo(() => {
    return jobHistoryValues
      .filter((elem: any) => {
        return (
          elem.step != undefined &&
          (elem.loss != undefined || elem.eval_loss != undefined)
        );
      })
      .map((elem: any) => {
        return {
          epoch: elem?.epoch,
          eval_loss: elem?.eval_loss,
          flops: elem?.flops,
          iter_time: elem?.iter_time,
          learning_rate: elem?.learning_rate,
          train_loss: elem?.loss,
          remaining_time: elem?.remaining_time,
          step: elem.step,
        };
      });
  }, [jobHistory]);
  const isJobCompleted = [
    "CANCELLED",
    "COMPLETED",
    "PARTIALLY COMPLETED",
    "FAILED",
  ].includes(activeJob?.status);
  const isModelReady = [
    "CANCELLED",
    "COMPLETED",
    "PARTIALLY COMPLETED",
  ].includes(activeJob?.status);
  const isResumable = ["CANCELLED", "PARTIALLY COMPLETED", "FAILED"].includes(
    activeJob?.status
  );

  return (
    <div
      className={
        "flex flex-col h-[calc(100vh-100px)] overflow-y-auto overflow-x-hidden mt-4"
      }
    >
      <div>
        <div className="flex justify-between">
          <div>
            {editName ? (
              <form
                onSubmit={handleSubmit}
                id="customName"
                className="bg-[#2B2B2B]"
              >
                <input
                  className="text-[#f1f1f1] bg-[#2B2B2B]"
                  autoFocus={true}
                  defaultValue={customModelName}
                />
                <button className="text-[#f1f1f1] px-4">Okay</button>
              </form>
            ) : (
              <div
                className={`text-2xl flex ${
                  isModelReady && "hover:text-lamini-primary"
                }`}
                {...(isModelReady && {
                  onClick: () => setEditName(true),
                  onMouseOver: () => setIsHoveringName(true),
                  onMouseOut: () => setIsHoveringName(false),
                })}
              >
                {customModelName === "" ? <>Untitled</> : customModelName}
                {isHoveringName && (
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    style={{
                      paddingLeft: "16px",
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="flex gap-4 justify-between">
            {!isJobCompleted && (
              <Button
                color="laminiDark"
                onClick={() =>
                  cancelJob(
                    activeJob?.job_id ?? "",
                    token,
                    setActiveJob,
                    activeJob,
                    jobs,
                    setJobs
                  )
                }
              >
                Pause
              </Button>
            )}
            {isResumable && (
              <Button
                color="laminiDark"
                onClick={() =>
                  resumeTraining(
                    activeJob?.job_id ?? "",
                    token,
                    setActiveJob,
                    activeJob,
                    jobs,
                    setJobs
                  )
                }
              >
                Resume
              </Button>
            )}
            {showShareIcons && (
              <ShareModel
                isPub={isPub}
                jobId={activeJob.job_id}
                modelName={activeJob.model_name}
                token={token}
                onShare={() =>
                  fetchJob(activeJob.job_id, () => {}, setJobHistory, token)
                }
              />
            )}
          </div>
        </div>
        <br />
        <div className="flex w-full justify-between">
          <p>Job ID</p>
          <div>
            <p className="m-auto">{statusChip(activeJob?.status ?? "")}</p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p>{activeJob?.job_id}</p>
          <div>
            <p className="text-right">{activeJob?.start_time}</p>
            {!isJobCompleted && <p className="text-right">{timeString}</p>}
          </div>
        </div>
        <br />
        <TrainJobInformation activeJob={activeJob} />
        <br />
      </div>
      <TabGroup selectedIndex={selectedTab} onChange={setSelectedTab}>
        <TabList className="border-b border-[#2b2b2b]">
          {tabs.map((label) => (
            <EvalTab key={label} label={label} />
          ))}
        </TabList>
        <TabPanels className="p-4 grow">
          <TabPanel>
            {jobEvalLoading ? (
              <ul aria-label="Eval Results">
                <li key={"loading"}>
                  <div className="w-full">
                    <Spinner enabled={true}></Spinner>
                  </div>
                </li>
              </ul>
            ) : jobEval && jobEval.length ? (
              <EvalResults jobEval={jobEval} modelId={activeJob.model_name} />
            ) : (
              <div className="text-[14px]">Not available.</div>
            )}
          </TabPanel>
          <TabPanel className="grow flex flex-col h-full overflow-hidden">
            {activeJob?.model_name ? (
              <InteractivePlayground
                token={token}
                default_model={activeJob.model_name}
                custom_name={activeJob.custom_model_name}
                shouldFetchModel={shouldFetchModel}
                outputLength={256}
                systemPrompt={""}
                maxInferenceTime={15}
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
                userInfo={userInfo}
              />
            ) : (
              <div className="text-[14px]">
                Not available yet. Please check back when your training job is
                completed.
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={data}
                margin={{ top: 20, right: 20, left: 5, bottom: 20 }}
              >
                <XAxis dataKey="step">
                  <Label value="Step" offset={0} position="bottom" />
                </XAxis>
                <YAxis
                  label={{
                    value: "Loss",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                <Line
                  type="monotone"
                  dataKey="train_loss"
                  stroke="#D3497E"
                  yAxisId={0}
                  connectNulls
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="eval_loss"
                  stroke="#82ca9d"
                  yAxisId={0}
                  connectNulls
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </TabPanel>
          <TabPanel>
            <TrainingLog token={token} job_id={activeJob.job_id} />
          </TabPanel>
          <TabPanel>
            <TrainArguments token={token} job_id={activeJob.job_id} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}
