import React from "react";
import {
  ClassifierEvaluationResult,
  BaseMetricSet,
  TunedMetricSet,
  TunedFuzzyMetricSet,
  BaseFuzzyMetricSet,
  WinLossRatios,
} from "../../types";
import { Button } from "../../components/system/catalyst/button";
import { Heading } from "../../components/system/catalyst/heading";
import { Text } from "../../components/system/catalyst/text";

const EvaluationDisplay = ({
  result,
}: {
  result: ClassifierEvaluationResult;
}) => {
  const formatMetric = (value: number | undefined) =>
    value ? `${(value * 100).toFixed(1)}%` : "N/A";

  const getMetricValue = (metrics: any, key: string) =>
    metrics?.[key] ?? undefined;

  const isMatch = (output: string | null, target: string) =>
    output?.toLowerCase() === target.toLowerCase();

  const downloadCSV = () => {
    const createMetricsCSV = () => {
      const metricPairs: Array<
        [keyof (BaseMetricSet & TunedMetricSet), string]
      > = [
        ["base_accuracy", "tuned_accuracy"],
        ["base_precision", "tuned_precision"],
        ["base_recall", "tuned_recall"],
        ["base_f1", "tuned_f1"],
      ];

      const metricsRows = [
        ["Metrics Summary"],
        ["Metric", "Base", "Tuned"],
        ...metricPairs.map(([baseKey, tunedKey]) => [
          baseKey.replace("base_", ""),
          formatMetric(getMetricValue(result.metrics, baseKey)),
          formatMetric(getMetricValue(result.metrics, tunedKey)),
        ]),
      ];

      return metricsRows
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");
    };

    const createResultsCSV = () => {
      const headers = ["Input", "Target", "Test Output", "Base Output"];
      const rows = result.predictions.map((pred) => [
        pred.input,
        pred.target,
        pred.test_output || "",
        pred.base_output || "N/A",
      ]);
      return [headers, ...rows]
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");
    };

    const downloadFile = (content: string, suffix: string) => {
      const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `classifier_${suffix}_${result.eval_job_id}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    downloadFile(createMetricsCSV(), "metrics");
    downloadFile(createResultsCSV(), "results");
  };

  const baseMetricKeys: Array<
    keyof (BaseMetricSet &
      BaseFuzzyMetricSet &
      Pick<WinLossRatios, "base_win_loss_ratio">)
  > = [
    "base_accuracy",
    "base_precision",
    "base_recall",
    "base_f1",
    "base_fuzzy_accuracy",
    "base_fuzzy_precision",
    "base_fuzzy_recall",
    "base_fuzzy_f1",
    "base_win_loss_ratio",
  ];

  const tunedMetricKeys: Array<
    keyof (TunedMetricSet &
      TunedFuzzyMetricSet &
      Pick<WinLossRatios, "tuned_win_loss_ratio">)
  > = [
    "tuned_accuracy",
    "tuned_precision",
    "tuned_recall",
    "tuned_f1",
    "tuned_fuzzy_accuracy",
    "tuned_fuzzy_precision",
    "tuned_fuzzy_recall",
    "tuned_fuzzy_f1",
    "tuned_win_loss_ratio",
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between">
        <Heading level={3}>Evaluation Job: {result.eval_job_id}</Heading>
        <div className="flex flex-col gap-2">
          <Text>Eval Data: {result.eval_data_id}</Text>
          <Text>Status: {result.status}</Text>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full border">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Model</th>
              {baseMetricKeys.map((key) => (
                <th key={key} className="px-4 py-2 border">
                  {key
                    .replace("base_", "")
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border font-bold">Base</td>
              {baseMetricKeys.map((key) => (
                <td key={key} className="px-4 py-2 border">
                  {formatMetric(getMetricValue(result.metrics, key))}
                </td>
              ))}
            </tr>
            <tr>
              <td className="px-4 py-2 border font-bold">Tuned</td>
              {tunedMetricKeys.map((key) => (
                <td key={key} className="px-4 py-2 border">
                  {formatMetric(getMetricValue(result.metrics, key))}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="overflow-x-auto">
          <table className="min-w-full my-4">
            <thead>
              <tr className="">
                <th className="px-4 py-2">Input</th>
                <th className="px-4 py-2">Target</th>
                <th className="px-4 py-2">Test Output</th>
                <th className="px-4 py-2">Base Output</th>
              </tr>
            </thead>
            <tbody>
              {result.predictions.map((pred, idx) => (
                <tr key={idx}>
                  <td className="px-4 py-2 border">{pred.input}</td>
                  <td className="px-4 py-2 border">{pred.target}</td>
                  <td
                    className={`px-4 py-2 border ${
                      isMatch(pred.test_output, pred.target)
                        ? "bg-green-100 dark:bg-green-900"
                        : "bg-red-100 dark:bg-red-900"
                    }`}
                  >
                    {pred.test_output}
                  </td>
                  <td
                    className={`px-4 py-2 border ${
                      pred.base_output && isMatch(pred.base_output, pred.target)
                        ? "bg-green-100 dark:bg-green-900"
                        : "bg-red-100 dark:bg-red-900"
                    }`}
                  >
                    {pred.base_output ?? "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          {/* ... existing predictions table ... */}
        </table>
        <Button onClick={downloadCSV}>Download CSV</Button>
      </div>
    </div>
  );
};
export default EvaluationDisplay;
