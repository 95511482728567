import React from "react";

interface TokenProps {
  /**
   * Token contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  active?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Token = ({ label, onClick, active = true }: TokenProps) => {
  return (
    <button
      type="button"
      className={
        `items-center font-sans rounded-full border px-2 text-xs font-light ${
          onClick != null ? "cursor-pointer" : ""
        } ` +
        (active
          ? `bg-lamini-primary text-white ${
              onClick != null ? "hover:bg-[#ff8d8e]" : ""
            } border-transparent `
          : `bg-[#121212] text-lamini-primary ${
              onClick != null ? "hover:bg-gray-50" : ""
            }  border-lamini-primary`)
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};
