import { TrainJob } from "../types";

export default function resumeTraining(
  job_id: string,
  token: string,
  setActiveJob: (activeJob: TrainJob) => void,
  activeJob: TrainJob,
  jobs: TrainJob[],
  setJobs: React.Dispatch<React.SetStateAction<TrainJob[]>>,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");
  if (job_id === "OOO") {
    return;
  }

  fetch(
    (process.env.REACT_APP_API_URL || "") +
      "/v1/train/jobs/" +
      job_id +
      "/resume",
    {
      method: "POST",
      headers: myHeaders,
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      const newActiveJob: TrainJob = {
        job_id: activeJob.job_id,
        status: json.status,
        model_name: activeJob.model_name,
        dataset_id: activeJob.dataset_id,
        custom_model_name: activeJob.custom_model_name,
        start_time: activeJob.start_time,
        resume_count: activeJob.resume_count,
        resume_limit: activeJob.resume_limit,
      };
      setActiveJob(newActiveJob);
      const newJobs = [...jobs];
      const index = newJobs.findIndex((job) => job.job_id === job_id);
      newJobs[index] = newActiveJob;
      setJobs(newJobs);
    })
    .catch((error) => {
      // setJobCancel({"status": "none", "message": "Error resuming job"})
    });
}
