import * as React from "react";
import fetchTrainingLog from "../../utils/fetchTrainingLog";
import { Spinner } from "../system/atoms/Spinner";

interface TrainingLogProps {
  token: string;
  job_id: string;
}

export default function TrainingLog({ token, job_id }: TrainingLogProps) {
  const [trainingLog, setTrainingLog] = React.useState<Array<string> | null>(
    null
  );
  React.useEffect(() => {
    fetchTrainingLog(job_id, setTrainingLog, token);
  }, []);

  // fetchTrainingLog has not finished yet so don't render jobs (show loading)
  if (trainingLog === null) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Spinner enabled={true}></Spinner>
        </div>
      </div>
    );
  }
  return (
    <div className="h-full font-mono text-xs">
      <div aria-label="Eval Results" className="overflow-y-auto w-full">
        {trainingLog.map((log_line, index) => {
          const isArt =
            log_line.includes("║", 0) ||
            log_line.includes("╔", 0) ||
            log_line.includes("╚", 0) ||
            log_line.includes("║", 7) ||
            log_line.includes("╔", 7) ||
            log_line.includes("╚", 7);
          return (
            <pre
              key={index}
              className={`break-words text-wrap ${
                isArt && "text-lamini-primary"
              }`}
            >
              {log_line}
            </pre>
          );
        })}
      </div>
    </div>
  );
}
