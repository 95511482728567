import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../components/system/catalyst/button";
import { Input } from "../../components/system/catalyst/input";
import { Text } from "../../components/system/catalyst/text";
import { Heading } from "../../components/system/catalyst/heading";
import { Centered } from "../../components/system/layouts/Centered";
import {
  Field,
  Fieldset,
  Label,
} from "../../components/system/catalyst/fieldset";
import { ClassExample } from "../../types";
import { useInitializeClassifier } from "../hooks/useClassifierProjects";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/20/solid";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../components/system/catalyst/dropdown";
import { PLAYGROUND_MODELS } from "../../components/playground/Playground";
import {
  GlobalDataContext,
  GlobalDataContextType,
} from "../../GlobalDataContext";

export const NewClassifierProject = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const globalData: GlobalDataContextType =
    React.useContext<GlobalDataContextType>(GlobalDataContext);
  const initializeProject = useInitializeClassifier();

  const [projectName, setProjectName] = useState("pet_classifier");
  const [baseModel, setBaseModel] = useState(
    (globalData.config.environment == "dev"
      ? PLAYGROUND_MODELS.find((model) => model.name === "Tiny Random Mistral")
          ?.value
      : PLAYGROUND_MODELS.find((model) => model.name === "Llama 3.1")?.value) ??
      "meta-llama/Meta-Llama-3.1-8B-Instruct"
  );
  const [classes, setClasses] = useState<ClassExample[]>([
    {
      className: "cat",
      examples: [
        "Cats are generally more independent and aloof than dogs, who are often more social and affectionate. Cats are also more territorial and may be more aggressive when defending their territory. Cats are self-grooming animals, using their tongues to keep their coats clean and healthy. Cats use body language and vocalizations, such as meowing and purring, to communicate.",
      ],
      description:
        "The cat (Felis catus) is a small domesticated carnivorous mammal. It is the only domesticated species of the family Felidae. Valued by humans for companionship and its ability to kill vermin, the cat has retractable claws, quick reflexes, and sharp teeth. Cat communication includes vocalizations like meowing and purring.",
    },
    {
      className: "dog",
      examples: [
        "Dogs are more pack-oriented and tend to be more loyal to their human family. Dogs, on the other hand, often require regular grooming from their owners, including brushing and bathing. Dogs use body language and barking to convey their messages. Dogs are also more responsive to human commands and can be trained to perform a wide range of tasks.",
      ],
      description:
        "The dog is a domesticated descendant of the wolf. It was the first species to be domesticated by humans, over 14,000 years ago. Dogs are pack animals known for their loyalty to humans and trainability. They communicate through body language and vocalizations like barking.",
    },
  ]);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = () => {
    setError(null);
    const classData = classes.reduce((acc, curr) => {
      acc[curr.className] = curr.examples;
      return acc;
    }, {} as Record<string, string[]>);

    initializeProject.mutate(
      {
        name: projectName,
        model_name: baseModel,
        classes: classes.reduce(
          (acc, c) => ({
            ...acc,
            [c.className]: c.description,
          }),
          {}
        ),
        examples: classData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["classifier-projects"] });
          navigate("/classify");
        },
        onError: (error: Error) => {
          setError(error.message);
        },
      }
    );
  };

  return (
    <Centered>
      <div className="w-full space-y-6 p-6">
        <div className="flex justify-between items-center">
          <Heading level={1}>New Classifier Project</Heading>
          <div className="flex justify-end gap-4">
            <Button onClick={() => navigate("/classify")}>Cancel</Button>
            <Button onClick={handleSubmit} color="lamini-primary">
              Create Project
            </Button>
          </div>
        </div>

        <div className="flex justify-between ">
          <div className="flex items-center gap-4">
            <div>
              <Text>Project Name</Text>
              <Input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Enter project name"
              />
            </div>
            {error && (
              <div className=" border-red-400 text-red-700 px-4 py-3 rounded relative">
                {error}
              </div>
            )}
          </div>

          <div>
            <Text>Base Model</Text>
            <Dropdown>
              <DropdownButton>
                {PLAYGROUND_MODELS.find((model) => model.value === baseModel)
                  ?.name || baseModel}
                <ChevronDownIcon className="h-4 w-4" />
              </DropdownButton>
              <DropdownMenu>
                {PLAYGROUND_MODELS.map((model) => (
                  <DropdownItem
                    key={model.value}
                    onClick={() => setBaseModel(model.value)}
                  >
                    {model.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        {classes.map((classItem, classIndex) => (
          <Fieldset
            key={classIndex}
            className="p-4 rounded border border-gray-700"
          >
            <div className="flex gap-4">
              <Field>
                <Text>Class Name</Text>
                <Input
                  value={classItem.className}
                  onChange={(e) => {
                    const newClasses = [...classes];
                    newClasses[classIndex].className = e.target.value;
                    setClasses(newClasses);
                  }}
                />
              </Field>

              <Field className="flex-1">
                <Text>Description</Text>
                <Input
                  value={classItem.description}
                  onChange={(e) => {
                    const newClasses = [...classes];
                    newClasses[classIndex].description = e.target.value;
                    setClasses(newClasses);
                  }}
                />
              </Field>

              {classes.length > 2 && (
                <Button
                  onClick={() =>
                    setClasses(classes.filter((_, idx) => idx !== classIndex))
                  }
                  outline
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              )}
            </div>
            <Text>Examples</Text>
            {classItem.examples.map((example, exampleIndex) => (
              <div key={exampleIndex} className="flex gap-2 mt-2 ml-8">
                <Input
                  value={example}
                  onChange={(e) => {
                    const newClasses = [...classes];
                    newClasses[classIndex].examples[exampleIndex] =
                      e.target.value;
                    setClasses(newClasses);
                  }}
                  placeholder="Example text"
                />
                {classItem.examples.length > 1 && (
                  <Button
                    onClick={() => {
                      const newClasses = [...classes];
                      newClasses[classIndex].examples = newClasses[
                        classIndex
                      ].examples.filter((_, idx) => idx !== exampleIndex);
                      setClasses(newClasses);
                    }}
                    outline
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                )}
              </div>
            ))}

            <Button
              onClick={() => {
                const newClasses = [...classes];
                newClasses[classIndex].examples.push("");
                setClasses(newClasses);
              }}
              className="mt-2"
            >
              Add Example
            </Button>
          </Fieldset>
        ))}

        <Button
          onClick={() =>
            setClasses([
              ...classes,
              {
                className: `class${classes.length + 1}`,
                examples: [""],
                description: "",
              },
            ])
          }
        >
          Add Class
        </Button>
      </div>
    </Centered>
  );
};
