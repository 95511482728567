import React from "react";
import { Text } from "../../components/system/catalyst/text";
import { ClassifierModel, ClassificationResult } from "../../types";
import {
  useClassificationHistory,
  useSingleClassifierEval,
} from "../hooks/useClassifierProjects";

type ModelDetailProps = {
  model: ClassifierModel;
};

export const ModelDetail = ({ model }: ModelDetailProps) => {
  const runEval = useSingleClassifierEval();
  const { data: classificationHistory } = useClassificationHistory(model.id);

  const renderClassificationResults = (results: ClassificationResult[]) => {
    const flatResults = Array.isArray(results[0]) ? results[0] : results;
    return (
      <ul>
        {flatResults.map(({ class_id, class_name, prob }) => (
          <li key={`${class_id}-${class_name}-${prob}`}>
            {`${class_name}: ${prob ? (prob * 100).toFixed(1) : 0}%`}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <li className="p-4 border rounded">
      <Text title={`Job ID: ${model.job_id}`}>Model ID: {model.id}</Text>

      {classificationHistory?.filter((h) => h.modelId === model.id).length >
        0 && (
        <div className="mt-4 border-t pt-4">
          <Text className="font-medium">Classification Results</Text>
          <ul className="space-y-2">
            {classificationHistory
              ?.filter((h) => h.modelId === model.id)
              .reverse()
              .slice(0, 5)
              .map((history) => (
                <li
                  key={history.timestamp + history.prompt}
                  className="text-sm"
                >
                  <div className="font-medium">{history.prompt}</div>
                  {renderClassificationResults(history.result)}
                </li>
              ))}
          </ul>
        </div>
      )}
    </li>
  );
};
