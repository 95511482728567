import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useState } from "react";
import { Button } from "../../components/system/catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../../components/system/catalyst/dialog";
import { Input } from "../../components/system/catalyst/input";
import { Text } from "../../components/system/catalyst/text";
import { Spacer } from "../../components/system/layouts/Spacer";
import { ClassExample } from "../../types";
import { useAddClassifierData } from "../hooks/useClassifierProjects";
import {
  Field,
  Fieldset,
  Label,
} from "../../components/system/catalyst/fieldset";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

export const AddDatasetDialog = ({
  projectName,
  onClose,
}: {
  projectName: string;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const addData = useAddClassifierData();
  const [datasetName, setDatasetName] = useState("new_dataset");
  const [classes, setClasses] = useState<ClassExample[]>([
    {
      className: "dog",
      examples: ["happy and friendly", "chews on shoes"],
      description: "class one",
    },
    {
      className: "cat",
      examples: ["enemy of mice", "likes to sit in the sun"],
      description: "class two",
    },
  ]);

  const handleSubmit = () => {
    const data = classes.reduce((acc, curr) => {
      acc[curr.className] = curr.examples;
      return acc;
    }, {} as Record<string, string[]>);

    addData.mutate(
      {
        project_name: projectName,
        dataset_name: datasetName,
        data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["classifier-datasets", projectName],
          });
          onClose();
        },
      }
    );
  };

  return (
    <Dialog
      open
      onClose={onClose}
      className=" focus:outline-none bg-lamini-light-background dark:bg-lamini-dark-background dark:text-[#ececec]"
    >
      <DialogTitle>Add Dataset</DialogTitle>
      <DialogBody>
        <Input
          value={datasetName}
          onChange={(e) => setDatasetName(e.target.value)}
          placeholder="Dataset Name"
        />
        <Spacer />

        <div className="flex items-center gap-2">
          <ExclamationCircleIcon className="h-5 w-5" />
          <Text>Only use classes that are present in the project</Text>
        </div>

        {classes.map((classItem, classIndex) => (
          <div key={classIndex} className="mt-4 rounded">
            <Fieldset>
              <Field>
                <Label>Class Name</Label>
                <Input
                  value={classItem.className}
                  onChange={(e) => {
                    const newClasses = [...classes];
                    newClasses[classIndex].className = e.target.value;
                    setClasses(newClasses);
                  }}
                  placeholder="Class Name"
                />
              </Field>
              <div className="mt-2 ml-8">
                {classItem.examples.map((example, exampleIndex) => (
                  <Input
                    key={exampleIndex}
                    value={example}
                    onChange={(e) => {
                      const newClasses = [...classes];
                      newClasses[classIndex].examples[exampleIndex] =
                        e.target.value;
                      setClasses(newClasses);
                    }}
                    className="mt-2"
                    placeholder="Example"
                  />
                ))}
                <Button
                  onClick={() => {
                    const newClasses = [...classes];
                    newClasses[classIndex].examples.push("");
                    setClasses(newClasses);
                  }}
                  className="mt-2 w-full"
                >
                  + Add Example
                </Button>
              </div>
            </Fieldset>
          </div>
        ))}
      </DialogBody>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="lamini-primary">
          Add Dataset
        </Button>
      </DialogActions>
    </Dialog>
  );
};
