import React from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../components/system/atoms/Spinner";
import { Divider } from "../../components/system/catalyst/divider";
import { Strong, Text } from "../../components/system/catalyst/text";
import { ClassifierProject } from "../../types";
import { useClassifierModels } from "../hooks/useClassifierProjects";
import { Heading } from "../../components/system/catalyst/heading";

export const ProjectListEntry = ({
  project,
}: {
  project: ClassifierProject;
}) => {
  const { data: models = [] } = useClassifierModels(project.project_name);

  const navigate = useNavigate();

  const pending = models.length === 0;

  const handleProjectClick = (projectId: string) =>
    navigate(`/classify/${projectId}`);

  return (
    <div
      className="p-4 cursor-pointer hover:bg-gray-800 rounded-lg"
      onClick={() => handleProjectClick(project.id)}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Heading level={2}>{project.project_name}</Heading>
        </div>
        <div className="flex flex-col items-center gap-4">
          <Text>{project.id}</Text>
          {pending ? (
            <div className="flex items-center gap-2 text-sm">
              <Spinner enabled={pending} />
              <span>Training</span>
            </div>
          ) : (
            <Text>
              {models.length} model{models.length === 1 ? "" : "s"}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
