import React from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ShareIcon } from "@heroicons/react/20/solid";
import { Code, Text } from "../system/catalyst/text";
import checkIcon from "../../assets/check-icon.svg";
import copyIcon from "../../assets/copy-icon.svg";
import setPublic from "../../utils/setPublic";
import { Button } from "../system/catalyst/button";

export default function ShareModel({
  isPub,
  jobId,
  modelName,
  token,
  onShare = () => {},
}: {
  isPub: boolean | null;
  jobId: string;
  modelName: string;
  token: string;
  onShare?: () => void;
}) {
  const [copiedLink, setCopiedLink] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setCopiedLink(false);
    setIsOpen(false);
  }, [jobId]);

  const handleShare = async () => {
    setPublic(modelName, token);
    onShare();
    setIsOpen(false);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.host}/share?model_id=${modelName}`
    );
    setCopiedLink(true);
    setTimeout(() => setCopiedLink(false), 2000);
  };

  if (isPub) {
    return (
      <div className="flex bg-gray-800 rounded-md p-2 mt-1 mr-1">
        <Text className="mr-2">Public link to model:</Text>
        <div className="text-xs flex cursor-pointer" onClick={copyLink}>
          <Code>{`${window.location.host}/share?model_id=${modelName}`}</Code>
          <img
            src={copiedLink ? checkIcon : copyIcon}
            alt={copiedLink ? "check-icon" : "copy-icon"}
            className="ml-2 w-[16px]"
          />
        </div>
      </div>
    );
  }

  return (
    <Menu as="div">
      <MenuButton as="div" onClick={() => setIsOpen(true)}>
        <Button color="laminiDark">
          <ShareIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
          Share Model
        </Button>
      </MenuButton>

      {isOpen && (
        <MenuItems className="absolute origin-top-right right-0 mt-2 w-[400px] rounded-md bg-gray-800 shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none p-4 gap-4">
          <MenuItem>
            <>
              <Text>
                Sharing this model will create a publicly accessible link. This
                cannot be undone. Are you sure you want to share?
              </Text>
              <div className="flex justify-center">
                <button
                  className="bg-lamini-primary text-white p-2 mt-2 rounded-md hover:bg-lamini-primary"
                  onClick={handleShare}
                >
                  Yes, confirm
                </button>
              </div>
            </>
          </MenuItem>
        </MenuItems>
      )}
    </Menu>
  );
}
