import * as React from "react";
import { useSearchParams } from "react-router-dom";
import InteractivePlayground from "./playground/InteractivePlayground";
import { Centered } from "./system/layouts/Centered";
import fetchModel from "../utils/fetchModel";
import { ChatHistoryItem } from "./system/atoms/ChatHistory";
import { UserInfo } from "../types";

interface SharePageProps {
  token: string;
  userInfo: UserInfo;
}

export default function SharePage(props: SharePageProps) {
  const [searchParams] = useSearchParams();
  const [isPub, setIsPub] = React.useState<boolean | null>(null);
  const [customModelName, setCustomModelName] = React.useState<string>("");
  const [chatHistory, setChatHistory] = React.useState<Array<ChatHistoryItem>>(
    [],
  );
  React.useEffect(
    () =>
      fetchModel(
        searchParams.get("model_id"),
        setIsPub,
        setCustomModelName,
        props.token,
      ),
    [],
  );

  if (isPub === null) {
    return null;
  }

  return (
    <Centered>
      <div className="grow flex flex-col h-full overflow-hidden p-2 md:p-4">
        <div className="pt-8 md:pt-12">
          <p className="text-xl md:text-2xl mt-2">
            {customModelName == "" ? "Shared Model" : customModelName}
          </p>
        </div>
        <InteractivePlayground
          token={props.token}
          default_model={searchParams.get("model_id")}
          custom_name={customModelName}
          shouldFetchModel={true}
          outputLength={256}
          systemPrompt={""}
          maxInferenceTime={15}
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
          userInfo={props.userInfo}
        />
      </div>
    </Centered>
  );
}
