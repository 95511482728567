import React, { useEffect, useState } from "react";
import { Button } from "../../components/system/catalyst/button";
import { Fieldset, Label } from "../../components/system/catalyst/fieldset";
import { Heading } from "../../components/system/catalyst/heading";
import { Select } from "../../components/system/catalyst/select";
import { Text } from "../../components/system/catalyst/text";
import { ClassifierEvalSet, ClassifierModel } from "../../types";
import {
  useClassifierEvalResults,
  useClassifierEvalSets,
  useClassifierModels,
  useCompareModels,
  useModelComparisonResults,
  useSingleClassifierEval,
} from "../hooks/useClassifierProjects";
import EvaluationDisplay from "./ClassifierEvalResult";
import { EvalEditor } from "./EvalEditor";
import { useQuery } from "@tanstack/react-query";

type Props = {
  projectName: string;
};

export const CompareClassifierModels = ({ projectName }: Props) => {
  const { data: models = [] } = useClassifierModels(projectName);
  const [testModel, setTestModel] = useState<string>("");
  const [baseModel, setBaseModel] = useState<string>("");
  const [showEvalEditor, setShowEvalEditor] = useState(false);

  const { data: evalSets = [] } = useClassifierEvalSets();

  const [selectedEvalSet, setSelectedEvalSet] = useState<string>(
    evalSets[0]?.eval_data_id ?? ""
  );

  useEffect(() => {
    if (evalSets.length > 0 && !selectedEvalSet) {
      setSelectedEvalSet(evalSets[0].eval_data_id);
    }
  }, [evalSets]);

  useEffect(() => {
    if (models.length >= 1 && !testModel) {
      setTestModel(models[0].id);
    }
    if (models.length >= 2 && !baseModel) {
      setBaseModel(models[1].id);
    }
  }, [models, testModel, baseModel]);

  const compareModels = useCompareModels();

  const comparisonResults = useModelComparisonResults(testModel, baseModel);

  const singleEval = useSingleClassifierEval();

  const singleEvalResults = useClassifierEvalResults(testModel);

  const handleModelSelect = (modelId: string, isTestModel: boolean) => {
    if (isTestModel) {
      setTestModel(modelId);
      if (modelId === baseModel) {
        setBaseModel("");
      }
    } else {
      setBaseModel(modelId);
      if (modelId === testModel) {
        setTestModel("");
      }
    }
  };

  const handleCompare = () => {
    if (!testModel || !baseModel) return;
    compareModels.mutate({
      testModelId: testModel,
      baseModelId: baseModel,
      evalData: evalSets.filter((set) => set.eval_data_id === selectedEvalSet),
    });
  };

  const handleEvaluate = () => {
    if (!testModel || !selectedEvalSet) return;
    singleEval.mutate({
      testModelId: testModel,
      baseModelId: baseModel || undefined,
      evalData: evalSets.filter((set) => set.eval_data_id === selectedEvalSet),
    });
  };

  if (models.length == 0) {
    return <Text>Train a model to see evaluation results</Text>;
  }

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <Fieldset className="space-y-4 max-w-md ">
          <Label>Test Model</Label>
          <Select
            value={testModel}
            onChange={(e) => handleModelSelect(e.target.value, true)}
          >
            {models.map((model: ClassifierModel) => (
              <option key={model.id} value={model.id}>
                {model.id}
              </option>
            ))}
          </Select>
          {models.length > 1 && (
            <>
              <Label>Base Model</Label>
              <Select
                value={baseModel}
                onChange={(e) => handleModelSelect(e.target.value, false)}
              >
                {models.map((model: ClassifierModel) => (
                  <option key={model.id} value={model.id}>
                    {model.id}
                  </option>
                ))}
              </Select>
            </>
          )}
        </Fieldset>

        <Fieldset className="flex flex-col gap-4">
          <Label>Eval Data</Label>
          <Button onClick={() => setShowEvalEditor(true)}>
            Create New Eval Data
          </Button>
          {evalSets.length > 0 && (
            <div className="space-y-4">
              <Label>Choose from existing eval data</Label>
              <Select
                value={selectedEvalSet}
                onChange={(e) => setSelectedEvalSet(e.target.value)}
              >
                {evalSets.map((set: ClassifierEvalSet) => (
                  <option key={set.eval_data_id} value={set.eval_data_id}>
                    {set.eval_data_id}
                  </option>
                ))}
              </Select>
            </div>
          )}
        </Fieldset>

        {showEvalEditor && (
          <EvalEditor onClose={() => setShowEvalEditor(false)} />
        )}
      </div>
      <div className="space-x-4">
        <Button
          onClick={handleEvaluate}
          disabled={singleEval.isPending || !testModel || !selectedEvalSet}
        >
          {singleEval.isPending ? "Evaluating..." : "Evaluate Model"}
        </Button>

        {baseModel && (
          <Button onClick={handleCompare} disabled={compareModels.isPending}>
            {compareModels.isPending ? "Comparing..." : "Compare Models"}
          </Button>
        )}

        {singleEval.isError && <div>Error: {singleEval.error.message}</div>}

        {compareModels.isError && (
          <div>Error: {compareModels.error.message}</div>
        )}

        {comparisonResults.data && (
          <div className="space-y-4 pt-8">
            {Object.entries(comparisonResults.data)
              .reverse()
              .map(([_, value]) => (
                <div key={value.eval_job_id} className="border rounded p-4">
                  <EvaluationDisplay result={value} />
                </div>
              ))}
          </div>
        )}

        {singleEvalResults.data && (
          <div className="space-y-4 pt-8">
            {Object.entries(singleEvalResults.data)
              .reverse()
              .map(([_, value]) => (
                <div key={value.eval_job_id} className="border rounded p-4">
                  <EvaluationDisplay result={value} />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
