import React, { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { PrismAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import pinkLlama from "../../../assets/icon_lamini.png";
import { ChatHistoryBotBubble } from "../../playground/ChatHistoryBotBubble";
import { ChatHistoryUserBubble } from "../../playground/ChatHistoryUserBubble";

export interface ChatHistoryItem {
  entity: string;
  message: string;
}

interface ChatHistoryProps {
  chatHistory: Array<ChatHistoryItem>;
  isGenerating: boolean;
}

const CodeBlock = ({
  language,
  value,
}: {
  language: string | null;
  value: string;
}) => {
  const normalizedLanguage = language
    ? language.replace("language-", "")
    : "python";
  return (
    <SyntaxHighlighter style={atomDark} language={normalizedLanguage}>
      {value}
    </SyntaxHighlighter>
  );
};

export default CodeBlock;

export const useCopyButton = () => {
  const [copiedIds, setCopiedIds] = useState<number[]>([]);

  const addToCopied = (id: number) =>
    setCopiedIds((prev: number[]) => [...prev, id]);

  const removeFromCopied = (id: number) =>
    setCopiedIds((prev: number[]) =>
      prev.filter((existingId) => existingId !== id)
    );

  const isCopied = (id: number) => copiedIds.includes(id);

  return { isCopied, addToCopied, removeFromCopied };
};

export const ChatHistory = (props: ChatHistoryProps) => {
  const scrollRef = useRef<HTMLInputElement>(null);
  const elemRef = useRef<HTMLInputElement>(null);
  const { isCopied, addToCopied, removeFromCopied } = useCopyButton();

  const handleCopy = (text: string, id: number) => {
    navigator.clipboard.writeText(text);
    addToCopied(id);
    setTimeout(() => {
      removeFromCopied(id);
    }, 3000); // time in milliseconds
  };

  useEffect(() => {
    if (elemRef.current === null || scrollRef.current === null) {
      return;
    }
    const bottom =
      scrollRef.current.scrollHeight -
      scrollRef.current.scrollTop -
      scrollRef.current.clientHeight;

    if (props.isGenerating && bottom < 100) {
      elemRef.current.scrollIntoView({ behavior: "auto", block: "end" });
    }
  }, [props.isGenerating, scrollRef.current?.scrollHeight]);

  return (
    <div className="w-full h-full rounded-[16px] overflow-hidden shadow-blackA7 bg-[#121212] border-[1px] border-[#393939] text-[#fff] font-sans text-[14px] p-4">
      <div
        ref={scrollRef}
        className="w-full h-full max-h-full rounded overflow-y-auto"
      >
        <div ref={elemRef} id="data" className="flex flex-col">
          {props.chatHistory.map((chat, index) => (
            <div className="flex w-full pb-4 first:mb-0 font-sans" key={index}>
              {chat.entity === "system" ? (
                chat.message === "pending" ? (
                  <div className="flex w-full">
                    <img
                      className="w-6 h-6 rounded-full shrink-0 mr-2"
                      src={pinkLlama}
                    />
                    <div className="my-auto text-[4px] text-lamini-primary align-middle">
                      <div className="loading" />
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto text-[#9E9E9E] text-[12px] whitespace-pre-wrap break-words">
                    <Markdown>{chat.message}</Markdown>
                  </div>
                )
              ) : chat.entity === "user" ? (
                <ChatHistoryUserBubble chat={chat} />
              ) : (
                <ChatHistoryBotBubble chat={chat} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
