import React, { useState } from "react";
import { Button } from "../../components/system/catalyst/button";
import { Input } from "../../components/system/catalyst/input";
import { Text } from "../../components/system/catalyst/text";
import { ClassifierModel } from "../../types";
import {
  useClassifierModels,
  useClassifyText,
  useTrainingJobStatus,
} from "../hooks/useClassifierProjects";
import { ModelDetail } from "./ModelDetail";
import { Fieldset } from "../../components/system/catalyst/fieldset";

export const ProjectModels = ({ projectName }: { projectName: string }) => {
  const { data: models, isLoading } = useClassifierModels(projectName);

  const [input, setInput] = useState("");
  const [trainingJobId] = useState<string | null>(null);
  const { data: jobStatus } = useTrainingJobStatus(trainingJobId);
  const classifyText = useClassifyText();

  const handleClassify = async () => {
    if (!input || !models?.length) return;

    models.map((model) =>
      classifyText.mutateAsync({
        modelId: model.id,
        prompt: input,
      })
    );
  };

  if (isLoading)
    return <p className="text-sm text-gray-400">Loading models...</p>;

  return (
    <div className="space-y-4">
      {models?.length == 0 && (
        <div className="space-y-2">
          <Text>No models found. Train your first classifier.</Text>
        </div>
      )}
      <div className="space-y-2">
        {trainingJobId && (
          <Text>
            Training job {jobStatus?.status || "starting"}...
            {jobStatus?.status === "completed" && " ✓"}
            {jobStatus?.status === "failed" && " ✗"}
            <br />
            ID: {trainingJobId}
          </Text>
        )}

        {models && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleClassify();
            }}
            className="flex gap-2"
          >
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter text to classify"
              className="max-w-md"
            />
            <Button type="submit" color="lamini-primary">
              Classify
            </Button>
          </form>
        )}
      </div>

      {models && (
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {models.map((model: ClassifierModel) => (
            <ModelDetail key={model.id} model={model} />
          ))}
        </ul>
      )}
    </div>
  );
};
