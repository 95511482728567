import React from "react";
import Markdown from "react-markdown";
import pinkLlama from "../../assets/icon_lamini.png";
import CodeBlock, { ChatHistoryItem } from "../system/atoms/ChatHistory";

export interface ChatHistoryUserBotProps {
  chat: ChatHistoryItem;
}

export const ChatHistoryBotBubble = ({ chat }: ChatHistoryUserBotProps) => {
  return (
    <div className="flex w-full max-w-full md:w-[80%]">
      {chat.entity === "model" && (
        <img
          className="w-6 h-6 rounded-full shrink-0 mr-2"
          src={pinkLlama}
        ></img>
      )}
      {chat.entity === "model" && (
        <div className={`ml-2 ${"block"} max-w-full`}>
          <div className="whitespace-pre-wrap break-words max-w-full bg-[#1d1d1d] border-[1px] border-[#393939] p-2 px-4 rounded-lg inline-block ">
            <Markdown
              components={{
                code: ({ className, children }) => {
                  const match = /language-(\w+)/.exec(className || "");
                  return (
                    <CodeBlock
                      language={match ? match[1] : null}
                      value={String(children).replace(/\n$/, "")}
                    />
                  );
                },
              }}
            >
              {chat.message}
            </Markdown>
          </div>
        </div>
      )}
    </div>
  );
};
