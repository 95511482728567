import * as React from "react";
import { useState } from "react";
import { Button } from "../../components/system/catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../../components/system/catalyst/dialog";
import { Input } from "../../components/system/catalyst/input";
import {
  Field,
  Fieldset,
  Label,
} from "../../components/system/catalyst/fieldset";
import { ClassifierEvalPair, ClassifierEvalSet } from "../../types";
import { useQueryClient } from "@tanstack/react-query";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useClassifierEvalSets } from "../hooks/useClassifierProjects";

export const EvalEditor = ({
  onClose,
  initialData,
}: {
  onClose: () => void;
  initialData?: ClassifierEvalSet;
}) => {
  const [evalName, setEvalName] = useState(initialData?.eval_data_id || "");
  const [pairs, setPairs] = useState<ClassifierEvalPair[]>(
    initialData?.eval_data || [
      {
        input: "a very fine canine companion",
        target: "dog",
      },
      {
        input: "chases mice",
        target: "cat",
      },
      {
        input: "the egyptians just loved these guys",
        target: "cat",
      },
    ]
  );

  const { saveSet } = useClassifierEvalSets();

  const handleSubmit = () => {
    saveSet.mutate(
      {
        evalData: pairs,
        evalName: evalName,
      },
      {
        onSuccess: () => onClose(),
      }
    );

    onClose();
  };

  const addPair = () =>
    setPairs([...pairs, { input: "", target: "" } as ClassifierEvalPair]);

  const updatePair = (
    index: number,
    field: keyof ClassifierEvalPair,
    value: string
  ) =>
    setPairs(
      pairs.map((pair, i) => (i === index ? { ...pair, [field]: value } : pair))
    );

  const removePair = (index: number) =>
    setPairs(pairs.filter((_, i) => i !== index));

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>New Evaluation Set</DialogTitle>
      <DialogBody>
        <Field>
          <Label>Set Name</Label>
          <Input
            value={evalName}
            onChange={(e) => setEvalName(e.target.value)}
            placeholder="Enter a name for the new evaluation set"
          />
        </Field>

        {pairs.map((pair, index) => (
          <Fieldset key={index} className="flex gap-2">
            <Field>
              <Label>Input</Label>
              <Input
                value={pair.input}
                onChange={(e) => updatePair(index, "input", e.target.value)}
                placeholder="Input text"
              />
            </Field>
            <Field>
              <Label>Target</Label>
              <Input
                value={pair.target}
                onChange={(e) => updatePair(index, "target", e.target.value)}
                placeholder="Expected classification"
              />
            </Field>
            <div className="flex items-end">
              <Button onClick={() => removePair(index)} outline>
                <TrashIcon className="h-4 w-4" />
              </Button>
            </div>
          </Fieldset>
        ))}

        <Button onClick={addPair}>Add Pair</Button>
      </DialogBody>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="lamini-primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
