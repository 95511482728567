import * as React from "react";
import { Spacer } from "../system/layouts/Spacer";
import { Container } from "../system/layouts/Container";
import { Centered } from "../system/layouts/Centered";
import { Padded } from "../system/layouts/Padded";
import LiveInferenceUsageChart from "../user/LiveInferenceUsageChart";
import { Heading } from "../system/catalyst/heading";

interface AdminPageProps {
  token: string;
}

export default function AdminPage(props: AdminPageProps) {
  return (
    <Centered>
      <Padded>
        <div className="mt-4">
          <Heading level={2}>Live GPU usage</Heading>
          <Spacer />
          <Container>
            <LiveInferenceUsageChart token={props.token} />
          </Container>
        </div>
      </Padded>
    </Centered>
  );
}
