import { useContext } from "react";
import { UserContext } from "../../components/user/UserContext";

export const useAuthenticatedUser = () => {
  const { profile } = useContext(UserContext);

  if (!profile?.token) {
    throw new Error("Profile token is not set");
  }

  return { token: profile.token, profile };
};
