import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { TrainJob } from "../../types";
import useInterval from "../../utils/useInterval";
import fetchJobs from "../../utils/fetchJobs";
import fetchJobEval, { JobEvalResult } from "../../utils/fetchJobEval";
import TrainPageEvalView from "./TrainPageEvalView";
import { Spinner } from "../system/atoms/Spinner";
import TrainCard from "./TrainCard";
import refreshJobs from "../../utils/refreshJobs";
import { useContext } from "react";
import {
  GlobalDataContext,
  GlobalDataContextType,
} from "../../GlobalDataContext";
import TrainPageSubmitJob from "./TrainPageSubmitJob";
import { useState } from "react";
import TrainDialog from "./TrainDialog";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { UserInfo } from "../../types";

interface TrainPageProps {
  token: string;
  userInfo: UserInfo;
}

export default function TrainPage({ token, userInfo }: TrainPageProps) {
  const globalData: GlobalDataContextType =
    useContext<GlobalDataContextType>(GlobalDataContext);

  const [searchParams] = useSearchParams();
  const [activeJob, setActiveJob] = React.useState<TrainJob | null>(null);
  const [jobs, setJobs] = React.useState<Array<TrainJob>>([]);
  const [jobEval, setJobEval] = React.useState<Array<JobEvalResult> | null>(
    null
  );
  const [jobEvalLoading, setJobEvalLoading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [onBottom, setOnBottom] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const fetchAndUpdateJobs = () => {
    fetchJobs(
      jobs,
      (newJobs) => {
        const uniqueJobs = Array.from(
          new Set([...jobs, ...newJobs].map((job) => job.job_id))
        )
          .map((id) => [...jobs, ...newJobs].find((job) => job.job_id === id))
          .filter((job): job is TrainJob => job !== undefined);
        setJobs(uniqueJobs);
      },
      token,
      setOnBottom,
      page
    );
  };

  React.useEffect(() => {
    fetchAndUpdateJobs();
  }, []);

  useInterval(() => {
    refreshJobs(jobs, setJobs, token, setOnBottom);
  }, 60000);

  const element = document.getElementById("train-jobs-list");
  if (element != null && onBottom == false) {
    element.onscroll = function (ev) {
      if (
        element.scrollTop + element.clientHeight >=
        element.scrollHeight - 30
      ) {
        setOnBottom(true);
        fetchJobs(jobs, setJobs, token, setOnBottom, page + 1);
        setPage(page + 1);
      }
    };
  }

  // fetchJobs has not finished yet so don't render jobs (show loading)
  if (jobs === null) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Spinner enabled={true}></Spinner>
        </div>
      </div>
    );
  }

  // Defaults active to first job if there are jobs
  if (jobs?.length && activeJob === null) {
    let default_active_job = jobs[0];
    if (searchParams.get("job") != null) {
      default_active_job =
        jobs.find((job) => job.job_id == searchParams.get("job")) || jobs[0];
    }
    setActiveJob(default_active_job);
    setJobEvalLoading(true);
    fetchJobEval(
      default_active_job.job_id,
      setJobEval,
      token,
      setJobEvalLoading
    );
  }

  return jobs?.length == 0 ? (
    <div className="w-full h-full">
      <TrainPageSubmitJob
        token={token}
        onJobSubmit={fetchAndUpdateJobs}
        env={globalData.config["environment"]}
      />
    </div>
  ) : (
    <div className="grid grid-cols-[300px_auto] gap-4 px-4 w-full h-full">
      <ul
        aria-label="Sidebar"
        className="border-r border-[#1d1d1d] p-4 overflow-y-auto max-h-[calc(100vh-100px)]"
        id="train-jobs-list"
      >
        <li
          className="bg-lamini-primary text-zinc-200 rounded-2xl p-4 mb-3 cursor-pointer hover:opacity-70"
          onClick={() => {
            setDialogIsOpen(true);
          }}
        >
          <div className="flex w-full justify-between text-sm text-nowrap ml-[-2rem]">
            <PlusCircleIcon className="w-5 ml-[3.8rem]" />
            Create a tuned model
          </div>
        </li>
        {dialogIsOpen && (
          <TrainDialog
            token={token}
            onClose={() => {
              setDialogIsOpen(false);
            }}
            refresh={fetchAndUpdateJobs}
            setActiveJob={setActiveJob}
            userInfo={userInfo}
          />
        )}

        {jobs
          .sort((a, b) => parseInt(b.job_id) - parseInt(a.job_id))
          .map((j) => (
            <TrainCard
              job={j}
              onClick={() => {
                setJobEvalLoading(true);
                setActiveJob(j);
                fetchJobEval(j.job_id, setJobEval, token, setJobEvalLoading);
                setSelectedTab(0);
              }}
              selected={j.job_id === activeJob?.job_id}
              key={j.job_id}
            />
          ))}
      </ul>

      <TrainPageEvalView
        activeJob={activeJob}
        jobs={jobs}
        setJobs={setJobs}
        jobEval={jobEval}
        token={token}
        jobEvalLoading={jobEvalLoading}
        setActiveJob={setActiveJob}
        shouldFetchModel={true}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showShareIcons={true}
        userInfo={userInfo}
      />
    </div>
  );
}
