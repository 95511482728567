import React from "react";
import { useClassifierDatasets } from "../hooks/useClassifierProjects";
import { ClassifierDataset } from "../../types";

export const ProjectDatasets = ({ projectName }: { projectName: string }) => {
  const { data: datasets, isLoading } = useClassifierDatasets(projectName);

  if (isLoading) return <p className="text-sm">Loading datasets...</p>;
  if (!datasets?.length)
    return <p className="text-sm">No additional example datasets</p>;

  return (
    <ul className="ml-4 text-sm min-w-48">
      {datasets.map((dataset: ClassifierDataset) => (
        <li key={dataset.id}>{dataset.dataset_name}</li>
      ))}
    </ul>
  );
};
