import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Divider } from "../../components/system/catalyst/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeading,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
} from "../../components/system/catalyst/dropdown";
import { Heading } from "../../components/system/catalyst/heading";
import { Text } from "../../components/system/catalyst/text";
import { Centered } from "../../components/system/layouts/Centered";
import { ClassifierProject } from "../../types";
import {
  useClassifierDatasets,
  useClassifierProjects,
  useTrainClassifier,
} from "../hooks/useClassifierProjects";
import { AddDatasetDialog } from "./AddDatasetDialog";
import { ProjectDatasets } from "./ProjectDatasets";
import { ProjectModels } from "./ProjectModels";
import { CompareClassifierModels } from "./CompareClassifierModels";

export function ProjectDetailPage() {
  const trainClassifier = useTrainClassifier();

  const { classifierProjectId } = useParams<{ classifierProjectId: string }>();
  const { data, isLoading } = useClassifierProjects();
  const [isAddDatasetOpen, setIsAddDatasetOpen] = useState(false);
  const project = data?.pages
    .flat()
    .find((p): p is ClassifierProject => p.id === classifierProjectId);

  const { data: datasets, isLoading: isLoadingDatasets } =
    useClassifierDatasets(project?.project_name ?? "");

  if (isLoading) return <Text>Loading...</Text>;
  if (!project) return <Text>Project not found</Text>;

  return (
    <Centered>
      <div className="my-8">
        <NavLink to="/classify">← Back to Projects</NavLink>
      </div>

      <div className="space-y-6">
        <div className="flex justify-between">
          <div className="flex gap-8 items-center">
            <Heading>{project.project_name}</Heading>
            <Text className="text-gray-500 text-sm">{project.id}</Text>
          </div>

          <Dropdown>
            <DropdownButton>
              <EllipsisHorizontalIcon />
            </DropdownButton>
            <DropdownMenu>
              <DropdownSection>
                <DropdownHeading>Additional Training Data</DropdownHeading>
                <DropdownItem>
                  <ProjectDatasets projectName={project.project_name} />
                </DropdownItem>
              </DropdownSection>
              <DropdownDivider />
              <DropdownItem onClick={() => setIsAddDatasetOpen(true)}>
                Add Training Data
              </DropdownItem>
              {!isLoadingDatasets && datasets?.length > 0 && (
                <>
                  <DropdownDivider />
                  <DropdownItem
                    onClick={() => trainClassifier.mutate(project.project_name)}
                  >
                    Train on Latest Data
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>

        <Divider />

        <section>
          <ProjectModels projectName={project.project_name} />
        </section>

        <Divider />

        <CompareClassifierModels projectName={project.project_name} />
      </div>
      {isAddDatasetOpen && (
        <AddDatasetDialog
          projectName={project.project_name}
          onClose={() => setIsAddDatasetOpen(false)}
        />
      )}
    </Centered>
  );
}
