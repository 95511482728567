import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/system/catalyst/button";
import { Divider } from "../../components/system/catalyst/divider";
import { Heading } from "../../components/system/catalyst/heading";
import { Strong, Text } from "../../components/system/catalyst/text";
import { Centered } from "../../components/system/layouts/Centered";
import { ClassifierProject } from "../../types";
import {
  useClassifierProjects,
  useProjectJobs,
} from "../hooks/useClassifierProjects";
import {
  ArrowsPointingOutIcon,
  LightBulbIcon,
} from "@heroicons/react/20/solid";
import { TagIcon } from "@heroicons/react/24/outline";
import { ProjectListEntry } from "./ProjectListEntry";

export const ClassifierProjectList = () => {
  const { data: projectPages } = useClassifierProjects();

  const navigate = useNavigate();

  const NewProjectButton = () => (
    <Button color="lamini-primary" onClick={() => navigate("/classify/new")}>
      New Project
    </Button>
  );

  if (!projectPages)
    return (
      <Centered>
        <Text>Loading...</Text>
      </Centered>
    );

  if (!projectPages?.pages?.length || projectPages.pages[0]?.length === 0)
    return (
      <Centered>
        <div className="text-center">
          <div className="mx-auto max-w-md rounded-2xl border border-zinc-950/10  dark:border-white/10 px-6 p-12 mt-24 space-y-8 flex flex-col items-center">
            <Heading level={3}>
              Let's build your first classifier!
            </Heading>
            <div className="space-y-2">
              <div className="flex items-center gap-3">
                <div className="rounded-lg bg-blue-500/10 p-2 dark:bg-blue-400/10">
                  <TagIcon className="size-4 text-blue-600 dark:text-blue-400" />
                </div>
                <Text>Label data with custom categories</Text>
              </div>

              <div className="flex items-center gap-3">
                <div className="rounded-lg bg-yellow-500/10 p-2 dark:bg-yellow-400/10">
                  <LightBulbIcon className="size-4 text-yellow-600 dark:text-yellow-400" />
                </div>
                <Text>Detect user intent automatically</Text>
              </div>

              <div className="flex items-center gap-3">
                <div className="rounded-lg bg-emerald-500/10 p-2 dark:bg-emerald-400/10">
                  <ArrowsPointingOutIcon className="size-4 text-emerald-600 dark:text-emerald-400" />
                </div>
                <Text>Route requests to the right place</Text>
              </div>
            </div>
            <NewProjectButton />
          </div>
        </div>
      </Centered>
    );

  return (
    <Centered>
      <div className="shadow-sm rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <Heading level={1}>Classifier Project</Heading>
          <NewProjectButton />
        </div>
        <div className="space-y-4">
          {projectPages?.pages.flatMap((projects, pageIndex) =>
            projects.map((project: ClassifierProject) => (
              <div key={`${pageIndex}-${project.id}`}>
                <ProjectListEntry project={project} />
                <Divider />
              </div>
            ))
          )}
        </div>
      </div>
    </Centered>
  );
};
