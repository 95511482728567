import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  AddDataPayload,
  ClassificationHistoryItem,
  ClassificationResult,
  ClassifierEvalInput,
  ClassifierEvalSet,
  ClassifierEvaluationResult,
  ClassifierModel,
  ClassifierTrainingJobStatus,
  InitializePayload,
  InitializeResponse,
} from "../../types";
import { getApiUrl } from "../../utils/getApiUrl";
import { useAuthenticatedUser } from "./useAuthenticatedUser";

type JobStatus = {
  jobId: string;
  status: "training" | "completed" | "failed";
};

const fetchProjectsPage = async ({
  token,
}: {
  pageParam?: number;
  limit?: number;
  token: string;
}) => {
  if (!token) throw new Error("Token is not set");

  const response = await fetch(getApiUrl("/v2/classifier/projects"), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data.projects.reverse();
};

export const useClassifierProjects = () => {
  const { token } = useAuthenticatedUser();

  return useInfiniteQuery({
    queryKey: ["classifier-projects", token],
    queryFn: ({ pageParam }) => fetchProjectsPage({ pageParam, token }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === 0 ? undefined : allPages.length,
    initialPageParam: 0,
    enabled: true,
  });
};

export const useInitializeClassifier = () => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: InitializePayload) => {
      const response = await fetch(getApiUrl("/v2/classifier/initialize"), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 497) {
        throw new Error(
          data.detail || data.message || "Project already exists"
        );
      }

      if (!response.ok) {
        throw new Error(
          data.detail ||
            data.message ||
            `Error ${response.status}: Failed to initialize classifier`
        );
      }

      return data as InitializeResponse;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["classifier-projects"] });
      queryClient.setQueryData(
        ["project-jobs", data.name],
        (old: JobStatus[] = []) => [
          ...old,
          { jobId: data.job_id, status: "training" },
        ]
      );
    },
  });
};

const fetchDatasets = async ({
  token,
  projectName,
}: {
  token: string;
  projectName: string;
}) => {
  if (!token) throw new Error("Token is not set");

  const response = await fetch(
    getApiUrl(`/v2/classifier/${encodeURIComponent(projectName)}/datasets`),
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch datasets");
  }

  const data = await response.json();
  return data.datasets;
};

export const useClassifierDatasets = (projectName: string) => {
  const { token } = useAuthenticatedUser();

  return useQuery({
    queryKey: ["classifier-datasets", projectName],
    queryFn: () => fetchDatasets({ token, projectName }),
    enabled: !!projectName,
  });
};

export const useAddClassifierData = () => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: AddDataPayload) => {
      const response = await fetch(getApiUrl("/v2/classifier/add"), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.detail ||
            data.message ||
            `Error ${response.status}: Failed to add classifier data`
        );
      }

      return data;
    },
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries({
        queryKey: ["classifier-datasets", payload.project_name],
      });
    },
    onError: (error: Error) => error.message,
  });
};

export const useTrainClassifier = () => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (projectName: string) => {
      const response = await fetch(getApiUrl("/v2/classifier/train"), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: projectName }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to train classifier");
      }

      return response.json();
    },
    onSuccess: (data, projectName) => {
      queryClient.setQueryData(
        ["project-training-jobs", projectName],
        (old: string[] = []) => [...old, data.job_id]
      );
    },
  });
};

const fetchClassifierModels = async ({
  token,
  projectName,
}: {
  token: string;
  projectName: string;
}) => {
  if (!token) throw new Error("Token is not set");

  const response = await fetch(
    getApiUrl(`/v2/classifier/${encodeURIComponent(projectName)}/models`),
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch models");
  }

  const data = await response.json();
  return data.models;
};

export const useClassifierModels = (projectName: string) => {
  const { token } = useAuthenticatedUser();

  return useQuery<ClassifierModel[]>({
    queryKey: ["classifier-models", projectName, token],
    queryFn: () => fetchClassifierModels({ token, projectName }),
    enabled: !!projectName,
    refetchInterval: 2000,
  });
};

export const useTrainingJobStatus = (
  jobId: string | null,
  projectName?: string
) => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useQuery<ClassifierTrainingJobStatus>({
    queryKey: ["training-job-status", jobId],
    queryFn: async () => {
      const response = await fetch(
        getApiUrl(`/v2/classifier/${jobId}/status`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch job status");
      }

      const data = await response.json();

      if (
        projectName &&
        (data.status === "completed" || data.status === "failed")
      ) {
        queryClient.setQueryData(
          ["project-jobs", projectName],
          (old: JobStatus[] = []) =>
            old.map((job) =>
              job.jobId === jobId ? { ...job, status: data.status } : job
            )
        );

        if (data.status === "completed") {
          queryClient.invalidateQueries({
            queryKey: ["classifier-models", projectName],
          });
        }
      }

      return data;
    },
    enabled: !!jobId,
  });
};

export const useClassifyText = () => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      modelId,
      prompt,
    }: {
      modelId: string;
      prompt: string;
    }) => {
      const response = await fetch(
        getApiUrl(`/v2/classifier/${modelId}/classify`),
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to classify text");
      }

      const data = await response.json();
      return {
        modelId,
        result: data.classification as ClassificationResult[],
        timestamp: new Date().toISOString(),
        prompt,
      };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["classification-history", data.modelId],
        (oldData: any[] = []) => [...oldData, data].slice(-10)
      );
    },
  });
};

export const useClassificationHistory = (modelId: string) => {
  return useQuery<ClassificationHistoryItem[]>({
    queryKey: ["classification-history", modelId],
    queryFn: () => [],
    initialData: [],
  });
};

export const useProjectTrainingJobs = (projectName: string) => {
  return useQuery<string[]>({
    queryKey: ["project-training-jobs", projectName],
    queryFn: () => [],
    initialData: [],
  });
};

const aggregateEvalData = (
  evalDataSets: ClassifierEvalSet[]
): ClassifierEvalSet =>
  evalDataSets.length === 1
    ? evalDataSets[0]
    : {
        eval_data: evalDataSets.flatMap((set) => set.eval_data),
        eval_data_id: evalDataSets
          .map((set) => set.eval_data_id)
          .sort()
          .join("_"),
      };

export const useSingleClassifierEval = () => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      testModelId,
      evalData,
      baseModelId,
    }: ClassifierEvalInput) => {
      const aggregatedData = aggregateEvalData(evalData);

      if (!aggregatedData.eval_data?.length) {
        throw new Error("Evaluation data is required");
      }

      if (!aggregatedData.eval_data_id?.trim()) {
        throw new Error("Evaluation data ID is required");
      }

      const response = await fetch(getApiUrl("/v1/eval/run"), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          test_model_id: testModelId,
          eval_data: aggregatedData.eval_data,
          eval_data_id: aggregatedData.eval_data_id,
          base_model_id: baseModelId || null,
          test_eval_type: "classifier",
          base_eval_type: "classifier",
          sbs: false,
          fuzzy_comparison: true,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(
          error.detail || error.message || "Failed to run evaluation"
        );
      }

      return response.json() as Promise<ClassifierEvaluationResult>;
    },
    onSuccess: (data, { testModelId }) => {
      queryClient.setQueryData(
        ["classifier-eval-results", testModelId],
        (oldData: any[] = []) => [...oldData, data]
      );

      queryClient.setQueryData(
        ["classifier-eval-results-by-job", data.eval_job_id],
        data
      );
    },
  });
};

export const useClassifierEvalResults = (modelId: string) => {
  return useQuery<ClassifierEvaluationResult[]>({
    queryKey: ["classifier-eval-results", modelId],
    queryFn: () => [],
    initialData: [],
    staleTime: 1000 * 60, // 1 minute
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};

export const useClassifierEvalResultsByJob = (evalJobId: string) => {
  return useQuery({
    queryKey: ["classifier-eval-results-by-job", evalJobId],
    queryFn: () => [],
    enabled: !!evalJobId,
  });
};

export const useProjectJobs = (projectName: string) => {
  return useQuery<JobStatus[]>({
    queryKey: ["project-jobs", projectName],
    queryFn: () => [],
    initialData: [],
    refetchInterval: 1000,
  });
};

export const useCompareModels = () => {
  const { token } = useAuthenticatedUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      testModelId,
      baseModelId,
      evalData,
    }: {
      testModelId: string;
      baseModelId: string;
      evalData: ClassifierEvalSet[];
    }) => {
      const aggregatedData = aggregateEvalData(evalData);

      const response = await fetch(getApiUrl("/v1/eval/run"), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          test_model_id: testModelId,
          base_model_id: baseModelId,
          eval_data: aggregatedData.eval_data,
          eval_data_id: aggregatedData.eval_data_id,
          fuzzy_comparison: true,
          sbs: true,
          test_eval_type: "classifier",
          base_eval_type: "classifier",
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(
          error.detail || error.message || "Failed to compare models"
        );
      }

      return response.json() as Promise<ClassifierEvaluationResult>;
    },
    onSuccess: (data, { testModelId, baseModelId }) => {
      queryClient.setQueryData(
        ["model-comparison", `${testModelId}-${baseModelId}`],
        (oldData: any[] = []) => [...oldData, data]
      );
    },
  });
};

export const useModelComparisonResults = (
  model1Id: string,
  model2Id: string
) => {
  return useQuery<ClassifierEvaluationResult[]>({
    queryKey: ["model-comparison", `${model1Id}-${model2Id}`],
    queryFn: () => [],
    initialData: [],
    staleTime: 1000 * 60,
    gcTime: 1000 * 60 * 60 * 24,
  });
};

export const useClassifierEvalSets = () => {
  const queryClient = useQueryClient();

  const query = useQuery<ClassifierEvalSet[]>({
    queryKey: ["classifier-eval-sets"],
    queryFn: () => {
      const stored = localStorage.getItem("classifier-eval-sets");
      return stored ? JSON.parse(stored) : [];
    },
    initialData: [],
    staleTime: 1000 * 60,
    gcTime: 1000 * 60 * 60 * 24,
  });

  const saveSet = useMutation({
    mutationFn: async ({
      evalData,
      evalName,
    }: {
      evalData: any[];
      evalName: string;
    }) => {
      const newSet = {
        eval_data: evalData,
        eval_data_id: evalName,
      };

      const stored = localStorage.getItem("classifier-eval-sets");
      const existingSets = stored ? JSON.parse(stored) : [];
      const updatedSets = [...existingSets, newSet];

      localStorage.setItem("classifier-eval-sets", JSON.stringify(updatedSets));
      return updatedSets;
    },
    onSuccess: (updatedSets) => {
      queryClient.setQueryData(["classifier-eval-sets"], updatedSets);
    },
  });

  return {
    ...query,
    saveSet,
  };
};
