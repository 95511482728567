import * as React from "react";
import Statsig from "statsig-js";
import { Centered } from "../system/layouts/Centered";
import { Container } from "../system/layouts/Container";
import { Padded } from "../system/layouts/Padded";
import { Spacer } from "../system/layouts/Spacer";
import GpuUsageChart, { GpuUsageInfo } from "./GpuUsageChart";
import InferenceUsageChart, { InferenceUsageInfo } from "./InferenceUsageChart";
import LiveInferenceUsageChart from "./LiveInferenceUsageChart";
import UsageChart, { UsageInfo } from "./UsageChart";
import { StatsigGate } from "../../types";
import { Heading } from "../system/catalyst/heading";

interface UsagePageProps {
  token: string;
}

async function getUserUsageFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/usage",
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await res.json();
  return data;
}

async function getUserGpuUsageFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/gpu_usage",
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await res.json();
  return data;
}

async function getUserInferenceUsageFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/inference_usage",
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const data = await res.json();
  return data;
}

export default function UsagePage(props: UsagePageProps) {
  const [usageInfo, setUsageInfo] = React.useState<UsageInfo | null>(null);
  const [gpuUsageInfo, setGpuUsageInfo] = React.useState<GpuUsageInfo | null>(
    null
  );
  const [InferenceUsageInfo, setInferenceUsage] =
    React.useState<InferenceUsageInfo | null>(null);

  React.useEffect(() => {
    getUserUsageFromBackend(props.token).then(setUsageInfo).catch(console.log);
  }, []);

  React.useEffect(() => {
    getUserGpuUsageFromBackend(props.token)
      .then(setGpuUsageInfo)
      .catch(console.log);
  }, []);

  React.useEffect(() => {
    getUserInferenceUsageFromBackend(props.token)
      .then(setInferenceUsage)
      .catch(console.log);
  }, []);

  return (
    <Centered>
      <Padded>
        {usageInfo !== null && (
          <div className="mt-4">
            <Heading level={2}>Credit Usage</Heading>
            <Spacer />
            <Container>
              <UsageChart usage={usageInfo.usage} />
            </Container>
          </div>
        )}
        {gpuUsageInfo !== null && (
          <div className="mt-4">
            <Heading level={2}>GPU (Training) Usage</Heading>
            <Spacer />
            <Container>
              <GpuUsageChart usage={gpuUsageInfo.usage} />
            </Container>
          </div>
        )}
        {InferenceUsageInfo !== null && (
          <div className="mt-4">
            <Heading level={2}>
              Number of Successful Inference + Embedding Requests
            </Heading>
            <Spacer />
            <Container>
              <InferenceUsageChart usage={InferenceUsageInfo.usage} />
            </Container>
          </div>
        )}
        {Statsig.checkGate(StatsigGate.LIVE_METRICS) && (
          <div className="mt-4">
            <Heading level={2}>Live GPU usage</Heading>
            <Spacer />
            <Container>
              <LiveInferenceUsageChart token={props.token} />
            </Container>
          </div>
        )}
        {/* Num GPUs used:
            Num training jobs:
            Num models: 
            Num tokens generated for inference: */}
      </Padded>
    </Centered>
  );
}
